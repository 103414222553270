import { IconMv } from "@/components/mvicons";

export default function SupportModal() {
	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col justify-center items-center w-full max-w-[380px] gap-6">
				<div className="flex flex-col justify-center items-center w-full gap-3">
					<div className="inline-flex flex-row items-center w-auto gap-4 text-white">
						<IconMv size={28} />
						<span className="text-lg sm:text-xl text-white leading-tight">
							Need Assistance?
						</span>
					</div>
					<span className="font-quagmire text-center uppercase font-bold text-2xl text-white leading-8">
						We’re here to help!
					</span>
				</div>
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<p className="text-center text-sm text-white leading-6">
						If you have any questions, need assistance, or wish to report an
						issue, feel free to reach out to us.
					</p>
					<p className="text-center text-sm text-white leading-6">
						You can contact our support team or admin through the following
						channels:
					</p>
				</div>
				<div className="flex flex-col w-full gap-2">
					<div className="flex flex-row justify-center w-full px-4 py-3 rounded-2xl bg-black border border-white/20 shadow">
						<a
							href="mailto:support@macroverse.com"
							className="text-center text-mvblue-300 leading-tight"
						>
							support@macroverse.com
						</a>
					</div>
					{/*<div className="flex flex-row justify-center w-full px-4 py-3 rounded-2xl bg-black border border-white/20 shadow">
						<a
							href="mailto:admin@macroverse.com"
							className="text-center text-mvblue-300 leading-tight"
						>
							admin@macroverse.com
						</a>
					</div>*/}
				</div>
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<p className="text-center text-sm text-white leading-6">
						Your satisfaction is our priority, and we're here to resolve your
						concerns promptly.
					</p>
				</div>
			</div>
		</div>
	);
}
