import clsx from "clsx";

type SkeletonProps = {
	className?: string;
	animate?: boolean;
};

export default function Skeleton({ className, animate }: SkeletonProps) {
	return (
		<div
			className={clsx("bg-white/10 min-h-4 min-w-4 ", className, {
				"animate-pulse": animate,
			})}
		/>
	);
}
