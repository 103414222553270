import Skeleton from "./skeleton";

export default function QuestCardSkeleton() {
	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-1 flex-col min-w-[260px] w-[260px] max-w-[260px] min-h-[350px] rounded-4xl bg-white bg-opacity-5 text-white/50 overflow-hidden">
				<div className="flex flex-col grow shrink w-full h-full p-4">
					<Skeleton className="w-8 h-8 rounded-xl" animate />
					<div className="flex flex-col w-full gap-2 mt-auto">
						<Skeleton className="w-3/4 h-6 rounded-xl" animate />
						<Skeleton className="w-2/4 h-6 rounded-xl" animate />
					</div>
				</div>
				<div className="flex flex-row justify-center w-full p-3 gap-2 bg-white bg-opacity-5">
					<Skeleton className="w-8 h-8 rounded-lg" animate />
					<Skeleton className="w-8 h-8 rounded-lg" animate />
					<Skeleton className="w-8 h-8 rounded-lg" animate />
				</div>
				<div className="flex flex-row justify-center w-full p-3">
					<Skeleton className="w-full max-w-[160px] h-10 rounded-2xl" animate />
				</div>
			</div>
		</div>
	);
}
