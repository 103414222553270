import Skeleton from "./skeleton";

export default function ExpCardSkeleton() {
	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-1 flex-col min-w-[260px] w-[260px] max-w-[260px] min-h-[350px] rounded-4xl bg-white bg-opacity-5 text-white/50 overflow-hidden">
				<div className="flex flex-col justify-center items-center grow shrink w-full h-full p-4">
					<Skeleton className="w-32 h-32 rounded-full" animate />
				</div>
				<div className="flex flex-row justify-center w-full p-3">
					<Skeleton className="w-full max-w-[160px] h-10 rounded-2xl" animate />
				</div>
			</div>
		</div>
	);
}
