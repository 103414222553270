import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import {
	IconInstagram,
	IconKickstarter,
	IconXtwitter,
} from "@/components/mvicons";
import SupportModal from "@/components/support-modal.tsx";
import WaitlistModal from "@/components/waitlist-modal.tsx";
import { useAuth } from "@/hooks/use-auth.tsx";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { toast } from "sonner";

export const Route = createFileRoute("/")({
	component: IndexComponent,
});

const callbackUrl =
	import.meta.env.VITE_PUBLIC_AUTH_CALLBACK_URL ||
	"https://www.macroverse.com/overview";

function IndexComponent() {
	const modal = useModal();

	const { authorize } = useAuth();

	const handleLogin = async () => {
		try {
			const response = await authorize({
				callbackUrl,
			});

			window.location.href = response.url;
		} catch (e) {
			toast.error("Unable to communicate with authorization server");
		}
	};

	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

	return (
		<div
			className={clsx(
				"flex flex-col w-full bg-black",
				isProduction ? "h-[100svh]" : "h-[calc(100svh-2rem)]",
			)}
		>
			<div
				className={clsx(
					"z-[3] fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-mvdark-950/80 to-transparent",
					isProduction ? "top-0" : "top-8",
				)}
			>
				<div className="inline-flex h-[44px]">
					<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
				</div>
				<div className="inline-flex flex-row w-auto gap-2">
					{isProduction ? (
						<Button
							className="font-quagmire backdrop-blur uppercase"
							onClick={() => {
								modal.openModal({
									title: "Waitlist Modal",
									component: <WaitlistModal />,
								});
							}}
							type="button"
							variant="gradient"
						>
							<ButtonLabel>Join Waitlist</ButtonLabel>
						</Button>
					) : (
						<Button
							className="font-quagmire backdrop-blur uppercase"
							onClick={handleLogin}
							type="button"
							variant="gradient"
						>
							<ButtonLabel>Login</ButtonLabel>
						</Button>
					)}
				</div>
			</div>

			<div className="flex flex-col items-center w-full h-full">
				<div className="relative flex justify-center items-center w-full h-screen md:h-[860px] bg-black overflow-hidden">
					<div className="z-[3] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center w-full mt-32 md:mt-38">
						<a
							className="inline-flex"
							href="https://www.kickstarter.com/projects/macroverse/archiecomics"
						>
							<Button className="font-quagmire uppercase" variant="shadow">
								Back it on Kickstarter
							</Button>
						</a>
					</div>
					<img
						className="z-[2] block h-full w-max max-w-max object-cover"
						src="/kickstarter-archie-landing-bg.png"
						alt="Macroverse"
					/>
					<div
						className="z-[1] absolute top-0 left-0 right-0 hidden md:block w-full h-screen md:h-[860px] bg-[url('/archie-stripe-bg.jpg')] bg-repeat-x bg-top bg-[length:1212px]"
						aria-hidden="true"
					/>
				</div>

				<div className="z-[3] absolute bottom-0 flex flex-col w-full py-4 px-4 md:px-6 gap-6">
					<div className="flex flex-col md:flex-row justify-center items-center w-full gap-6 md:gap-12">
						<div className="inline-flex flex-row items-center w-auto gap-6 md:gap-12">
							<div className="inline-flex w-[75px] mr-2">
								<img
									className="block h-auto w-full"
									src="/svg/macroverse-logo-colored-layered.svg"
									alt="Macroverse"
								/>
							</div>
							<a
								href="https://macroverse.onelink.me/VuAp/mvweb"
								className="group inline-flex p-[2px] rounded-xl bg-mvlogo-gradient shadow-lg"
							>
								<div className="relative inline-flex flex-row items-center w-auto p-2 pr-4 rounded-xl gap-2 bg-mvdark-600 overflow-hidden">
									<div className="z-[2] inline-flex w-[40px] h-[40px] rounded-lg shadow-lg bg-mvdark-900 overflow-hidden">
										<img src="/macroverse-app-icon.png" alt="" />
									</div>
									<div className="z-[2] flex flex-col w-auto">
										<span className="font-semibold text-sm text-white leading-4">
											Get the
										</span>
										<span className="font-quagmire font-bold text-md text-white uppercase leading-4">
											Macroverse App
										</span>
									</div>
									<div className="z-[1] absolute inset-0 w-full h-full bg-mvlogo-gradient opacity-0 transition-all group-hover:opacity-20 pointer-events-none" />
								</div>
							</a>
						</div>
						<div className="flex flex-row items-center w-auto gap-8">
							<div className="inline-flex flex-row items-center gap-4">
								<a
									href="https://www.instagram.com/macroversehq"
									className="font-semibold text-white"
								>
									<IconInstagram size={24} />
								</a>
								<a
									href="https://x.com/macroverse"
									className="font-semibold text-white"
								>
									<IconXtwitter size={24} />
								</a>
								<a
									href="https://www.kickstarter.com/profile/macroverse/created"
									className="font-semibold text-white"
								>
									<IconKickstarter size={24} />
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-full gap-4 mb-2">
						<div className="flex flex-row flex-wrap justify-center w-full gap-x-4">
							<a href="/" className="text-sm text-white/70 hover:text-white">
								Terms and Condition
							</a>
							<a href="/" className="text-sm text-white/70 hover:text-white">
								Privacy Policy
							</a>
							<button
								onClick={() => {
									modal.openModal({
										title: "Support Modal",
										component: <SupportModal />,
									});
								}}
								type="button"
								className="text-sm text-white/70 hover:text-white"
							>
								Support
							</button>
						</div>
						<div className="flex flex-row justify-center w-full">
							<span className="text-xs text-white/50">
								© 2024 Macroverse Media Inc. All Rights Reserve
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
