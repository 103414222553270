import { usePortalContainer } from "@/components/layouts/portal-container-context.tsx";
import type { User } from "@/types/user.ts";
import clsx from "clsx";

type PortalContainerProps = {
	children: React.ReactNode;
};

type PortalContainerBodyProps = {
	children: React.ReactNode;
};

type PortalContainerContentProps = {
	children: React.ReactNode;
	className?: string;
};

const PortalContainerContent = ({
	children,
	className,
}: PortalContainerContentProps) => {
	return (
		<div
			className={clsx(
				"flex flex-col w-full max-w-[960px] pt-12 md:pt-20 pb-4 md:pb-6 px-4 md:px-6 gap-12",
				className,
			)}
		>
			{children}
		</div>
	);
};

const PortalContainerHead = ({
	children,
	profile,
}: {
	children: React.ReactNode;
	profile?: User;
}) => {
	const { user } = usePortalContainer();
	const profileBgColor =
		user?.userMetadata.profileBgColor || "bg-mv-profile-black";

	const getProfileBgColor = (profileBgColor: string) => {
		if (profileBgColor === "bg-mv-profile-black") return "#1F1F1F";
		if (profileBgColor === "bg-mv-profile-navy") return "#091736";
		if (profileBgColor === "bg-mv-profile-purple") return "#180838";
		if (profileBgColor === "bg-mv-profile-blue") return "#0B2038";
		if (profileBgColor === "bg-mv-profile-wine") return "#27000C";
		return "#1F1F1F";
	};

	console.log(getProfileBgColor(profileBgColor));
	const bgColor = getProfileBgColor(profileBgColor);

	return (
		<div
			className={clsx(
				"z-[2] relative flex justify-center items-center w-full h-[210px]",
				{
					"bg-mvmain-gradient-angled": !profile?.bannerPictureUrl,
					"bg-black": profile?.bannerPictureUrl,
				},
			)}
		>
			{children}
			{profile?.bannerPictureUrl && (
				<img
					className="h-full w-max max-w-max"
					src={profile?.bannerPictureUrl}
					alt="Macroverse"
				/>
			)}
			{!profile?.bannerPictureUrl && (
				<div
					className="absolute inset-0 w-full h-full"
					style={{
						backgroundImage: `linear-gradient(to top, ${bgColor}, transparent)`,
					}}
				/>
			)}
		</div>
	);
};

const PortalContainerBody = ({ children }: PortalContainerBodyProps) => {
	return (
		<div className="z-[1] flex flex-col w-full items-center">{children}</div>
	);
};

const PortalContainer = ({ children }: PortalContainerProps) => {
	const { user } = usePortalContainer();
	const profileBgColor =
		user?.userMetadata.profileBgColor || "bg-mv-profile-black";

	return (
		<div className={clsx("flex flex-col w-full min-h-screen", profileBgColor)}>
			{children}
		</div>
	);
};

export {
	PortalContainer,
	PortalContainerBody,
	PortalContainerHead,
	PortalContainerContent,
};
